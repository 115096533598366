import { Vue, Component } from 'vue-property-decorator';
import { Getter, State as StateClass } from 'vuex-class';
import { MetaInfo } from 'vue-meta';
import Support from '@/components/common/support/Support.vue';
import BannerLegal from '@/components/common/banner-legal/BannerLegal.vue';
import Navbar from '@/components/common/navbar/Navbar.vue';
import FooterExpanded from '@/components/common/footer-expanded/FooterExpanded.vue';
import { State } from '@/store/models';
import { titleTemplate } from '@/helpers/meta/title-template';
import { glossary, intercom } from '../../../whitelabel.config';

@Component({
  components: {
    Support,
    BannerLegal,
    Navbar,
    FooterExpanded,
  },
})
export default class Knowledge extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.knowledge.title').toString()),
    };
  }

  intercom = intercom;

  @StateClass user!: State['user'];
  @Getter isUserLoggedIn!: boolean;

  get tabs(): { title: string, routeName: string, icon: string[] }[] {
    const tabs = [
      { title: this.$t('help.faq.faq').toString(), routeName: 'knowledge-faq', icon: ['fa', 'question'] },
    ];

    if (glossary) {
      tabs.unshift({ title: this.$t('common.glossary').toString(), routeName: 'knowledge-glossary', icon: ['fa', 'book'] });
    }

    return tabs;
  }

  get userLoggedIn(): boolean {
    return this.isUserLoggedIn;
  }

  /**
   * Return if tab is active.
   */
  isActiveTab(tabRouteName: string): boolean {
    return (this.$route.name || '').startsWith(tabRouteName);
  }
}
